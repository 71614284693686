import * as React from 'react'
import { Helmet } from 'react-helmet'
import Graph from '../components/Graph'
import Layout from '../components/layout'
import Header from '../components/Header'
import { Link } from 'gatsby'
const app = () => {
  return (
    <Layout>
      <header className="App-header">
        <Helmet>
          <title>Investing calculator - risk and outcome</title>
          <title>Investing calculator - stocks, bonds, and more</title>
          <meta
            name="description"
            content="See how common it is to earn or lose a certain amout of money. Try different durations and asset classes."
          />
        </Helmet>
        <Header links={[]} texts={[]} />
        <h1>Investment calculator</h1>
        <div
          style={{
            paddingLeft: '40px',
            paddingRight: '40px',
            maxWidth: '700px',
            marginBottom: '50px',
            textAlign: 'left',
          }}
        >
          <p>
            Understand the risks and the potential outcome of investments. Try
            different asset classes, different amounts invested, and durations
            to see how probable different outcomes are, based on previous
            performance. This calculator can help you understand how different
            asset classes and investment perform. This tool can help you with
            investment risk analysis. Understanding investment risk can help you
            make a more informed investment decision.
          </p>
          <p>
            There is no guarentee this investment simulator reflects real world
            performance. It can however give an indication of the future based
            historical data for different asset classes.
          </p>
        </div>
        <Graph></Graph>
        <div
          style={{
            paddingLeft: '40px',
            paddingRight: '40px',
            maxWidth: '700px',
            textAlign: 'left',
          }}
        >
          <h3>Why investing is important</h3>
          <p>
            Investing money in the stock market has historically been one of the
            best ways to get a return on your money. Historically, the longer
            you invest your money, the lower the risk of losing money is. Before
            investing it is generally a good idea to pay off high interest debt.
            If you want, try our{' '}
            <Link to={'/calculator/debt'}>debt calculator</Link>
          </p>
          <p>
            Investing periodically and letting compound interest work over a
            long period of time is a good way to build personal net worth. All
            investments have risk and you can lose parts of, or your entire
            initial investment. Only invest what you can afford to lose.
          </p>
        </div>
        <div
          style={{
            paddingLeft: '40px',
            paddingRight: '40px',
            maxWidth: '700px',
            marginBottom: '50px',
            textAlign: 'left',
          }}
        >
          <h3>Considerations</h3>
          <p>
            Profits from investing is in most countries considered capital gains
            and gains are taxable. In several countries and jurisdictions there
            are types of accounts that have a different, beneficial, tax
            structure. In USA there are for example{' '}
            <a href="https://en.wikipedia.org/wiki/401(k)">401(k)</a> and{' '}
            <a href="https://en.wikipedia.org/wiki/Roth_IRA#:~:text=A%20Roth%20IRA%20is%20an,provided%20certain%20conditions%20are%20met.">
              Roth IRA
            </a>{' '}
            accounts.
          </p>
          <p></p>
        </div>
      </header>
    </Layout>
  )
}

export default app
