import React, { useEffect } from 'react'

import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import '../layout.css'
import InputSection from '../InputSection'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { motion } from 'framer-motion'

import returns from '../../data/returns'

import { withStyles, makeStyles } from '@material-ui/core/styles'

const assets = returns.map((x) => x.asset)
//const dataSources = returns.map((x) => x.data)
const assetReturns = returns.map((x) => x.return)
const assetStdDev = returns.map((x) => x.stdDev)

const options = assets.map((x, i) => (
  <option key={i} value={i}>
    {x}
  </option>
))

const CssTextField = withStyles({
  root: {
    '& .MuiInputBase-root.Mui-disabled': {},
    '& .MuiFormLabel-root.Mui-disabled': {},

    '& label.Mui-focused': {},
    '& .MuiInput-underline:after': {},
    '& .MuiOutlinedInput-root': {
      '& fieldset': {},
      '&:hover fieldset': {},
      '&.Mui-focused fieldset': {},
    },
  },
})(TextField)

const CssSelectField = withStyles({
  root: {
    height: '19px',
    padding: '10.5px',
    minWidth: '225px',
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiInputBase-root': {},
    '& .MuiFormLabel-root.Mui-disabled': {},
    '& .MuiInputBase-input': { borderColor: '#0aa' },

    '& label.Mui-focused': {},
    '& .MuiInput-underline:after': {},
    '& .MuiOutlinedInput-root': {
      '& fieldset': { borderColor: '#0aa' },
      '&:hover fieldset': {},
      '&.Mui-focused fieldset': {},
    },
  },
})(Select)

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  item: {
    display: 'flex',
    float: 'left',
    minWidth: '150px',
  },
  select: {
    minWidth: '150px',
  },
  margin: {
    margin: theme.spacing(1),
  },
  externalLink: {
    textDecoration: 'underline',
    marginTop: '16px',
    float: 'left',
    fontSize: '14px',
    marginLeft: '6px',
  },
}))

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function numberWithCommas2(x) {
  if (x === 0) {
    return 0
  }
  let text = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const commas = text.split(',').length - 1
  if (commas === 1) {
    return text.split(',')[0] + 'k'
  } else if (commas > 1 && text.split(',')[0].length <= 2) {
    return text.split(',')[0] + '.' + text.split(',')[1][0] + 'm'
  } else if (commas > 1) {
    return text.split(',')[0] + 'm'
  }
}

const Graph = (props) => {
  const { width } = useWindowDimensions()
  const [isMobile, setIsMobile] = React.useState(width < 501)

  useEffect(() => {
    setIsMobile(width < 501)
  }, [width, isMobile])
  const stdInputProps = {
    type: 'number',
    size: 'small',
    variant: 'outlined',
    className: useStyles().item,
  }

  const classes = useStyles()
  const [years, setYears] = React.useState(10)
  const [yearsText, setYearsText] = React.useState('10')
  const [nSimulations, setNSimulations] = React.useState(
    isMobile ? 2000 : 10000
  )
  const [moneyInvested, setMoneyInvested] = React.useState(10000)
  const [moneyInvestedPerMonth, setMoneyInvestedPerMonth] = React.useState(2000)

  const [inputInvestmentReturn, setInvestmentReturn] = React.useState(
    assetReturns[0]
  )
  const [inputStdDev, setStdDev] = React.useState(assetStdDev[0])

  const [selectedAssetClass, setSelectedAssetClass] = React.useState(0)

  const investmentReturn = 1 + inputInvestmentReturn / 100
  const stdDev = inputStdDev / 100
  const moneyInvestedPerYear = moneyInvestedPerMonth * 12

  const setAssetClass = (index) => {
    setSelectedAssetClass(index)
    if (index >= 0) {
      setInvestmentReturn(assetReturns[index])
      setStdDev(assetStdDev[index])
    }
  }

  if (years !== Math.min(years, 100) && !isNaN(years)) {
    setYears(Math.min(years, 100))
    setYearsText(Math.min(years, 100))
  }

  /*if (isNaN(years)) {
    setYears(0)
  }*/

  // Math.max is to not count withdrawls into invested amount
  const principal = moneyInvested + Math.max(0, moneyInvestedPerYear * years)

  function randn_bm(mean, stdDev) {
    var u = 0,
      v = 0
    while (u === 0) u = Math.random() //Converting [0,1) to (0,1)
    while (v === 0) v = Math.random()
    return (
      mean +
      stdDev * Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v)
    )
  }

  let over = 0
  let under = 0

  var simulation = []
  for (let i = 0; i < nSimulations; i++) {
    const res = randn_bm(investmentReturn, stdDev)
    simulation[i] = res * moneyInvested + moneyInvestedPerYear
    simulation[i] = Math.max(simulation[i], 1)
  }

  let minn = 100000

  //next years
  for (let year = 1; year < years; year++) {
    for (let i = 0; i < nSimulations; i++) {
      const res = randn_bm(investmentReturn, stdDev)
      simulation[i] = (simulation[i] + moneyInvestedPerYear) * res

      minn = Math.min((simulation[i] + moneyInvestedPerYear) * res, minn)
      //simulation[i] = Math.max(simulation[i], 1)
    }
  }

  let average = 0

  for (let i = 0; i < nSimulations; i++) {
    if (simulation[i] > principal) over++
    else under++

    average += simulation[i] / nSimulations
  }

  let data = []

  let nBuckets = Math.round(width / 110)
  //make sure last bucket has label
  if (isMobile) {
    nBuckets = Math.round((width - 50) / 70)
  }

  nBuckets = Math.min(nBuckets, 20)

  //let buckets = []

  // takes around 25-50 ms for 50k simulations
  simulation.sort((a, b) => a - b)

  const bucketCutoffLow = 0.005
  let bucketCutoffHigh = Math.max(1, years / 4) * 0.005

  const bottomBucket = simulation[(nSimulations * bucketCutoffLow).toFixed(0)]
  const topBucket =
    simulation[(nSimulations * (1 - bucketCutoffHigh)).toFixed(0) - 1]

  let bucketCount = []
  let lowCutoffs = []
  let highCutoffs = []

  for (let i = 0; i < nBuckets; i++) {
    const totalDiff = topBucket - bottomBucket

    let lowCutoff = 0
    let highCutoff = topBucket

    if (i === 0) {
      highCutoff = bottomBucket + (totalDiff * (i + 1)) / nBuckets
    } else if (i === bucketCount - 1) {
      highCutoff = simulation[simulation.length] + 1
    } else {
      lowCutoff = bottomBucket + (totalDiff * i) / nBuckets
      highCutoff = bottomBucket + (totalDiff * (i + 1)) / nBuckets
    }

    lowCutoffs.push(lowCutoff)
    highCutoffs.push(highCutoff)
  }

  for (let i = 0; i < nBuckets; i++) {
    const h = highCutoffs[i]
    const l = lowCutoffs[i]

    if (principal < h && principal > l) {
      const hDiff = h - principal

      for (let j = 0; j < nBuckets; j++) {
        highCutoffs[j] -= hDiff
        lowCutoffs[j] -= hDiff
      }

      break
    }
  }
  let currentSearchIndex = 0
  let maxBucketCount = 0
  for (let i = 0; i < nBuckets; i++) {
    const lowCutoff = lowCutoffs[i]
    const highCutoff = highCutoffs[i]

    let thisBucketCount = 0
    let money = simulation[currentSearchIndex]

    while (money < highCutoff && money > lowCutoff) {
      money = simulation[currentSearchIndex]
      currentSearchIndex++
      thisBucketCount++
    }

    bucketCount.push(thisBucketCount)
    maxBucketCount = Math.max(maxBucketCount, thisBucketCount)
  }

  for (let i = 0; i < nBuckets; i++) {
    if (lowCutoffs[i] >= principal && highCutoffs[i] > principal)
      data.push({
        country: i,
        profit: bucketCount[i],
        loss: 0,
      })
    else
      data.push({
        country: i,
        profit: 0,
        loss: bucketCount[i],
      })
  }

  const barWidth = isMobile ? 45 : 50

  return (
    <>
      <div style={{ display: 'flex', alignContent: 'center' }}>
        <div
          style={{
            display: width < 897 ? 'flex' : 'flex',
            maxWidth: '100vw',
            flexWrap: 'wrap',
            paddingTop: '15px',
            boxShadow: '0 2px 8px rgba(0,0,0,.2)',
            background: 'white',
            width: isMobile ? '100vw' : 'inherit',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              paddingBottom: '20px',
              maxWidth: '800px',
              display: isMobile ? 'inline-flex' : 'inherit',
              paddingLeft: isMobile ? '8px' : '48px',
              paddingRight: isMobile ? '8px' : '48px',
            }}
          >
            <div>
              <h2
                style={{
                  marginBottom: '1.2rem',
                  width: '100%',
                }}
              >
                Your investment plan
              </h2>
              <InputSection>
                <p style={{ marginTop: '7px' }}>Years investing</p>
                <CssTextField
                  {...stdInputProps}
                  InputProps={{
                    inputProps: {
                      max: 100,
                      min: 1,
                    },
                  }}
                  onChange={(e) => {
                    const value = parseInt(e.target.value)
                    //dont update if 0
                    if (value) {
                      setYears(value)
                    }
                    setYearsText(e.target.value)
                  }}
                  value={yearsText}
                />
              </InputSection>
              <InputSection>
                <p style={{ marginTop: '7px' }}>Starting investment</p>
                <CssTextField
                  {...stdInputProps}
                  label=""
                  onChange={(e) => setMoneyInvested(parseInt(e.target.value))}
                  value={moneyInvested}
                />
              </InputSection>
              <InputSection>
                <p style={{ marginTop: '7px' }}>Investment per month</p>
                <CssTextField
                  {...stdInputProps}
                  label=""
                  onChange={(e) =>
                    setMoneyInvestedPerMonth(parseInt(e.target.value))
                  }
                  value={moneyInvestedPerMonth}
                />
              </InputSection>
            </div>
          </div>

          <div
            style={{
              paddingBottom: '40px',
              maxWidth: '800px',
              display: 'inline-flex',
              paddingLeft: isMobile ? '8px' : '48px',
              paddingRight: isMobile ? '8px' : '48px',
            }}
          >
            <div>
              <h2 style={{ marginBottom: '1.2rem' }}>Your investment</h2>
              <InputSection>
                <p style={{ marginTop: '7px' }}>Asset</p>
                <FormControl variant="outlined" className={classes.formControl}>
                  <CssSelectField
                    native
                    autoWidth={true}
                    className={classes.select}
                    value={selectedAssetClass}
                    onChange={(e) => setAssetClass(e.target.value)}
                    inputProps={{
                      name: 'age',
                    }}
                  >
                    <option value={-1}>Custom</option>
                    {options}
                  </CssSelectField>
                </FormControl>
              </InputSection>
              <InputSection>
                <p style={{ marginTop: '7px' }}>Yearly return (%)</p>
                <CssTextField
                  {...stdInputProps}
                  label=""
                  onChange={(e) => {
                    setInvestmentReturn(e.target.value)
                    setAssetClass(-1)
                  }}
                  value={inputInvestmentReturn}
                />
              </InputSection>
              <InputSection>
                <p style={{ marginTop: '7px' }}>Standard deviation (%)</p>
                <CssTextField
                  {...stdInputProps}
                  label=""
                  onChange={(e) => {
                    setStdDev(e.target.value)
                    setAssetClass(-1)
                  }}
                  value={inputStdDev}
                />
              </InputSection>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div
          style={{
            marginTop: '0px',
            display: 'flex',
            width: '100%',
            maxWidth: '100vw',
            justifyContent: 'left',
          }}
        >
          <span
            style={{
              marginTop: '16px',
              float: 'left',
              fontSize: '14px',
            }}
          >
            {'Sources and more info:'}
          </span>
          <Link
            rel="noopener"
            className={classes.externalLink}
            target="_blank"
            href="https://admainnew.morningstar.com/webhelp/FAQs/What_assumptions_goalplans.htm"
          >
            {'1'}
          </Link>
          <span
            style={{
              marginTop: '16px',
              float: 'left',
              color: 'white',
              fontSize: '14px',
            }}
          >
            ,
          </span>
          <Link
            rel="noopener"
            className={classes.externalLink}
            target="_blank"
            href="http://people.duke.edu/~charvey/Classes/ba350_1997/history/history.htm"
          >
            2
          </Link>
        </div>
      </div>

      {false && (
        <h3
          style={{
            marginTop: '70px',
            marginBottom: '88px',
            textAlign: isMobile ? 'center' : 'center',
            width: isMobile ? '90vw' : '70vw',
          }}
        >
          {'Probability of different outcomes'}
        </h3>
      )}

      <h2
        style={{
          marginTop: '70px',
          position: 'relative',
          marginBottom: '0px',
          textAlign: 'center',
        }}
      >
        Outcome probabilities based on historical data
      </h2>
      <p
        style={{
          position: 'relative',
          textAlign: 'center',
          fontSize: '14px',
        }}
      >
        (after {nSimulations} simulations)
      </p>

      <div
        style={{
          //background: 'white',
          marginTop: '-60px',
          paddingTop: '0px',
          paddingLeft: '80px',
          paddingRight: '80px',
          paddingBottom: '80px',
          //boxShadow: '0 2px 8px rgba(0,0,0,.2)',

          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
        }}
      >
        {bucketCount.map((count, i) => (
          <div
            key={i}
            style={{
              padding: '4px',
              display: 'flex',
              marginTop: '88px',
              height: '44vh',
              alignItems: 'flex-end',
              justifyContent: 'center',
              flexDirection: 'row',
            }}
          >
            <div style={{ color: '#111', maxWidth: barWidth + 'px' }}>
              <motion.div
                initial={{ scale: 1, width: barWidth, height: 0 }}
                animate={{
                  rotate: 0,
                  scale: 1,
                  width: barWidth,
                  height: (38 * count) / maxBucketCount + 'vh',
                }}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background:
                    lowCutoffs[i] >= principal && highCutoffs[i] > principal
                      ? '#5DAD64'
                      : '#FA886E',
                  borderRadius: '20px',
                }}
                transition={{
                  type: 'spring',
                  stiffness: 260,
                  damping: 30,
                }}
              >
                <p
                  style={{
                    margin: 0,
                  }}
                >
                  {((100 * count) / nSimulations).toFixed(1)}%
                </p>
              </motion.div>
              <p
                style={{
                  marginLeft: 13,
                  whiteSpace: 'nowrap',
                  transform: 'rotate(50deg)',
                }}
              >
                {'$ ' +
                  numberWithCommas2(Math.max(0, lowCutoffs[i].toFixed(0))) +
                  ' - ' +
                  numberWithCommas2(highCutoffs[i].toFixed(0))}
              </p>
            </div>
          </div>
        ))}
      </div>
      <h3
        style={{
          margin: '5px',
        }}
      >
        Total invested:
        <span style={{ color: '#537A60' }}>
          {' $' + numberWithCommas(principal)}
        </span>
      </h3>
      <h3
        style={{
          margin: '5px',
        }}
      >
        Median return:{' '}
        <span style={{ color: '#537A60' }}>
          ${numberWithCommas(simulation[nSimulations / 2].toFixed(0))}
        </span>
      </h3>
      <h3
        style={{
          margin: '5px',
        }}
      >
        Average return:{' '}
        <span style={{ color: '#537A60' }}>
          ${numberWithCommas(average.toFixed(0))}
        </span>
      </h3>

      {false && (
        <span
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <h2 style={{ marginTop: 30 }}>Investment outcome</h2>
          <div
            style={{
              width: '80%',
              marginTop: 20,
              maxWidth: '700px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <motion.div
              initial={{ scale: 1, width: 100, height: 40 }}
              animate={{
                rotate: 0,
                scale: 1,
                width: Math.min(width, 700) * (under / (over + under)),
                height: 40,
              }}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#FA886E',
                borderRadius: '16px',
                height: '40px',
              }}
              transition={{
                type: 'spring',
                stiffness: 260,
                damping: 30,
              }}
            >
              <p
                style={{
                  margin: 0,
                }}
              >
                {((100 * under) / (over + under)).toFixed(1)}%
              </p>
            </motion.div>
            <motion.div
              initial={{ scale: 1, width: 100, height: 40 }}
              animate={{
                rotate: 0,
                scale: 1,
                width: Math.min(width, 700) * (over / (over + under)),
                height: 40,
              }}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#5DAD64',
                borderRadius: '16px',
                height: '40px',
              }}
              transition={{
                type: 'spring',
                stiffness: 260,
                damping: 30,
              }}
            >
              <p
                style={{
                  margin: 0,
                }}
              >
                {((100 * over) / (over + under)).toFixed(1)}%
              </p>
            </motion.div>
          </div>
          <div
            style={{
              width: '80%',
              maxWidth: '700px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <h4 style={{ marginTop: 14 }}>loss</h4>
            <h4 style={{ marginTop: 14 }}>profit</h4>
          </div>
        </span>
      )}
      {false && (
        <h2 style={{ marginTop: '3rem' }}>
          Result from
          <CssTextField
            style={{
              marginLeft: '0.6rem',
              marginRight: '0.6rem',
              maxWidth: '6.1rem',
            }}
            label=""
            type="number"
            size="small"
            variant="outlined"
            onChange={(e) => setNSimulations(parseInt(e.target.value))}
            value={nSimulations}
          />
          simulations
        </h2>
      )}

      <span style={{ marginBottom: '32px' }} />
    </>
  )
}

export default Graph
