const returns = [
  {
    asset: 'U.S. Large-cap Growth Stocks',
    data: 'Russell Top 200 Growth',
    return: 8.25,
    stdDev: 21.82,
  },
  {
    asset: 'U.S. Large-cap Value Stocks',
    data: 'Russell Top 200 Value',
    return: 9.6,
    stdDev: 17.47,
  },
  {
    asset: 'U.S. Mid-cap Growth Stocks',
    data: 'Russell Midcap Growth',
    return: 10.41,
    stdDev: 23.22,
  },
  {
    asset: 'U.S. Mid-cap Value Stocks',
    data: 'Russell Midcap Value',
    return: 12.48,
    stdDev: 19.17,
  },
  {
    asset: 'U.S. Small-cap Growth Stocks',
    data: 'Russell 2000 Growth',
    return: 9.98,
    stdDev: 27.62,
  },
  {
    asset: 'U.S. Small-cap Value Stocks',
    data: 'Russell 2000 Value',
    return: 13.29,
    stdDev: 22.46,
  },
  {
    asset: 'Non-U.S. Dev Stocks',
    data: 'MSCI EAFE',
    return: 10.02,
    stdDev: 20.62,
  },
  {
    asset: 'Non-U.S. Emrg Stocks',
    data: 'MMSCI Emerging Mkts',
    return: 14.35,
    stdDev: 29.65,
  },
  {
    asset: 'U.S. Investment Grade Bonds',
    data: 'Barclays US Agg Bond TR USD',
    return: 3.36,
    stdDev: 7.08,
  },
  {
    asset: 'U.S. High-Yield Bonds',
    data: 'Barclay US Corporate High Yield TR USD',
    return: 7.37,
    stdDev: 11.33,
  },
  {
    asset: 'Non-U.S. Dev Bonds',
    data: 'Citi WGBI NonUSD USD',
    return: 3.08,
    stdDev: 11.22,
  },
  {
    asset: 'Cash',
    data: 'Citi Treasurey Bill 3 Mon USD',
    return: 0.97,
    stdDev: 1.67,
  },
  {
    asset: 'Commodities',
    data: 'DJ UBS Commodity TR',
    return: 4.48,
    stdDev: 17.86,
  },
  {
    asset: 'U.S. Real Estate',
    data: 'FTSE NAREIT-Equity',
    return: 8.92,
    stdDev: 23.55,
  },
]

export default returns
